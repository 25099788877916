var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hi8app"},[_c('Banner',{attrs:{"banner":_vm.banner}}),_c('section',{staticClass:"col-lg-9 col-11 mx-auto first_section",attrs:{"id":"eight_elements"}},[_c('h1',{staticClass:"fw-bold"},[_vm._v("八元素")]),_c('div',{staticClass:"line_bottom m-auto mb-5"}),_c('div',{staticClass:"col-10 mx-auto"},[_c('p',[_vm._v(" 短影音直播是未來新媒體最大商機、每個用戶、商家、企業將透過短影音直播建立“創造、娛樂、音樂、購物、新聞、搜尋、社交、商機”八大元素關聯性、嗨吧以八元素為核心創造未來美好生活。 ")]),_c('EightElements',{attrs:{"data":_vm.eight_elements}})],1)]),_c('section',{staticClass:"col-lg-9 col-11 mx-auto",attrs:{"id":"connectLife"}},[_c('h1',{staticClass:"fw-bold"},[_vm._v("接軌生活")]),_c('div',{staticClass:"line_bottom m-auto mb-5"}),_c('div',{staticClass:"col-10 row mx-auto"},[_c('p',[_vm._v(" 嗨吧用短影音內容觀看行為觸發，包含了電商購物、知識訂閱、社交網路，建立個人行為數據庫、不斷提升個人數據 AI 最佳化、幫助用戶建立 AI 物聯網分母。 ")]),_c('div',{staticClass:"col-6 my-3 wow animate__animated animate__fadeInUp"},[_c('div',{staticClass:"bg-cover",style:({
            backgroundImage:
              'url(' +
              require('@/assets/hi8app/img_hi8school_life01.jpg') +
              ')',
          })},[_vm._m(0)])]),_c('div',{staticClass:"col-6 my-3 wow animate__animated animate__fadeInUp"},[_c('div',{staticClass:"bg-cover",style:({
            backgroundImage:
              'url(' +
              require('@/assets/hi8app/img_hi8school_life02.jpg') +
              ')',
          })},[_vm._m(1)])]),_c('div',{staticClass:"col-6 my-3 wow animate__animated animate__fadeInUp"},[_c('div',{staticClass:"bg-cover",style:({
            backgroundImage:
              'url(' +
              require('@/assets/hi8app/img_hi8school_life03.jpg') +
              ')',
          })},[_vm._m(2)])]),_c('div',{staticClass:"col-6 my-3 wow animate__animated animate__fadeInUp"},[_c('div',{staticClass:"bg-cover",style:({
            backgroundImage:
              'url(' +
              require('@/assets/hi8app/img_hi8school_life04.jpg') +
              ')',
          })},[_vm._m(3)])])])]),_c('section',{staticClass:"col-lg-9 col-11 mx-auto",attrs:{"id":"competitiveness"}},[_c('h1',{staticClass:"fw-bold"},[_vm._v("核心競爭力")]),_c('div',{staticClass:"line_bottom m-auto mb-5"}),_c('div',{staticClass:"col-10 row mx-auto"},[_c('p',[_vm._v(" 團隊需要具備娛樂圈、市場零售、影音心理學基礎、製作短影音內容獲取大數據、結合數學演算邏輯團隊建立AI演算引擎。 ")]),_vm._m(4),_c('div',{staticClass:"col-12 col-lg-4 my-3 center-line"},[_c('div',{staticClass:"line"},[_c('div',{staticClass:"ball",class:{ 'ball-right': _vm.competitiveness },on:{"click":function($event){_vm.competitiveness = !_vm.competitiveness}}})])]),_c('div',{staticClass:"col-12 col-lg-4 my-3"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.competitiveness),expression:"competitiveness"}],staticClass:"bg-blue wow animate__animated animate__zoomIn",attrs:{"data-wow-duration":"1s"}},[_vm._m(5)])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.competitiveness),expression:"competitiveness"}],staticClass:"col-12 mt-3 wow animate__animated animate__zoomIn",attrs:{"data-wow-duration":"1s"}},[_c('h3',[_vm._v("翰成數位掌握短影音市場數據精萃")]),_c('h3',[_vm._v("未來將是台灣最重要 AI 數據管理公司")])])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-mask"},[_c('div',{staticClass:"bg-text"},[_vm._v("內容電商")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-mask"},[_c('div',{staticClass:"bg-text"},[_vm._v("直播電商")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-mask"},[_c('div',{staticClass:"bg-text"},[_vm._v("知識訂閱")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-mask"},[_c('div',{staticClass:"bg-text"},[_vm._v("社交關係")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"col-12 col-lg-4 my-3"},[_c('div',{staticClass:"bg-black"},[_c('div',{staticClass:"bg-text"},[_vm._v("嗨吧 APP")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"bg-text"},[_vm._v(" 短影音內容"),_c('br'),_vm._v(" 精準獲取行為數據"),_c('br'),_vm._v(" 建立AI物聯網 ")])}]

export { render, staticRenderFns }