<template>
  <div class="eight_elements">
    <div class="col-12 my-lg-5 my-2 d-flex">
      <div
        class="elements_block wow animate__animated animate__fadeInTopLeft"
        data-wow-duration="1s"
      >
        <img :src="data[0].img" />
        <p>{{ data[0].title }}</p>
      </div>
      <div
        class="elements_block wow animate__animated animate__slideInDown"
        data-wow-duration="1.2s"
      >
        <img :src="data[1].img" />
        <p>{{ data[1].title }}</p>
      </div>
      <div
        class="elements_block wow animate__animated animate__fadeInTopRight"
        data-wow-duration="1.5s"
      >
        <img :src="data[2].img" />
        <p>{{ data[2].title }}</p>
      </div>
    </div>
    <div class="col-12 my-lg-5 my-2 d-flex">
      <div
        class="elements_block wow animate__animated animate__slideInLeft"
        data-wow-duration="3s"
      >
        <img :src="data[3].img" />
        <p>{{ data[3].title }}</p>
      </div>
      <div
        class="elements_block wow animate__animated animate__pulse animate__infinite"
        data-wow-duration="2.5s"
      >
        <img :src="data[4].img" />
        <p v-html="data[4].title"></p>
      </div>
      <div
        class="elements_block wow animate__animated animate__slideInRight"
        data-wow-duration="3s"
      >
        <img :src="data[5].img" />
        <p>{{ data[5].title }}</p>
      </div>
    </div>
    <div class="col-12 my-lg-5 my-2 d-flex">
      <div
        class="elements_block wow animate__animated animate__slideInUp"
        data-wow-duration="1s"
      >
        <img :src="data[6].img" />
        <p>{{ data[6].title }}</p>
      </div>
      <div
        class="elements_block wow animate__animated animate__slideInUp"
        data-wow-duration="1.2s"
      >
        <img :src="data[7].img" />
        <p>{{ data[7].title }}</p>
      </div>
      <div
        class="elements_block wow animate__animated animate__slideInUp"
        data-wow-duration="1.5s"
      >
        <img :src="data[8].img" />
        <p>{{ data[8].title }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>
<style lang="scss">
.eight_elements {
  .d-flex {
    justify-content: space-between;
  }
  .elements_block {
    width: 28%;
    min-height: 28%;
    margin: 0.5rem;
    background: #fff;
    padding: 2rem;
    -webkit-box-shadow: 3px 3px 5px #4444415d;
    -moz-box-shadow: 3px 3px 5px #4444415d;
    box-shadow: 3px 3px 5px #4444415d;
    &:hover {
      -webkit-box-shadow: 10px 10px 5px #4444415d;
      -moz-box-shadow: 10px 10px 5px #4444415d;
      box-shadow: 10px 10px 5px #4444415d;
      transition: 0.5s;
    }
    p {
      font-size: 1rem;
      font-weight: bold;
    }
    img {
      width: 50%;
    }
  }
}
@media screen and (min-width: 501px) and( max-width: 989px) {
  .eight_elements .elements_block {
    padding: 1rem;
    p {
      font-size: 0.5rem;
      font-weight: bold;
      margin: 0;
    }
  }
}
@media screen and (max-width: 500px) {
  .eight_elements .elements_block {
    padding: 0rem;
    p {
      font-size: 0.5rem;
      font-weight: bold;
    }
    img {
      width: 30%;
    }
  }
}
</style>
