<template>
  <div class="hi8app">
    <Banner :banner="banner" />
    <section class="col-lg-9 col-11 mx-auto first_section" id="eight_elements">
      <h1 class="fw-bold">八元素</h1>
      <div class="line_bottom m-auto mb-5"></div>
      <div class="col-10 mx-auto">
        <p>
          短影音直播是未來新媒體最大商機、每個用戶、商家、企業將透過短影音直播建立“創造、娛樂、音樂、購物、新聞、搜尋、社交、商機”八大元素關聯性、嗨吧以八元素為核心創造未來美好生活。
        </p>
        <EightElements :data="eight_elements" />
      </div>
    </section>
    <section class="col-lg-9 col-11 mx-auto" id="connectLife">
      <h1 class="fw-bold">接軌生活</h1>
      <div class="line_bottom m-auto mb-5"></div>
      <div class="col-10 row mx-auto">
        <p>
          嗨吧用短影音內容觀看行為觸發，包含了電商購物、知識訂閱、社交網路，建立個人行為數據庫、不斷提升個人數據
          AI 最佳化、幫助用戶建立 AI 物聯網分母。
        </p>
        <div class="col-6 my-3 wow animate__animated animate__fadeInUp">
          <div
            class="bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/hi8app/img_hi8school_life01.jpg') +
                ')',
            }"
          >
            <div class="bg-mask">
              <div class="bg-text">內容電商</div>
            </div>
          </div>
        </div>
        <div class="col-6 my-3 wow animate__animated animate__fadeInUp">
          <div
            class="bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/hi8app/img_hi8school_life02.jpg') +
                ')',
            }"
          >
            <div class="bg-mask">
              <div class="bg-text">直播電商</div>
            </div>
          </div>
        </div>
        <div class="col-6 my-3 wow animate__animated animate__fadeInUp">
          <div
            class="bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/hi8app/img_hi8school_life03.jpg') +
                ')',
            }"
          >
            <div class="bg-mask">
              <div class="bg-text">知識訂閱</div>
            </div>
          </div>
        </div>
        <div class="col-6 my-3 wow animate__animated animate__fadeInUp">
          <div
            class="bg-cover"
            :style="{
              backgroundImage:
                'url(' +
                require('@/assets/hi8app/img_hi8school_life04.jpg') +
                ')',
            }"
          >
            <div class="bg-mask">
              <div class="bg-text">社交關係</div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="col-lg-9 col-11 mx-auto" id="competitiveness">
      <h1 class="fw-bold">核心競爭力</h1>
      <div class="line_bottom m-auto mb-5"></div>
      <div class="col-10 row mx-auto">
        <p>
          團隊需要具備娛樂圈、市場零售、影音心理學基礎、製作短影音內容獲取大數據、結合數學演算邏輯團隊建立AI演算引擎。
        </p>
        <div class="col-12 col-lg-4 my-3">
          <div class="bg-black">
            <div class="bg-text">嗨吧 APP</div>
          </div>
        </div>
        <div class="col-12 col-lg-4 my-3 center-line">
          <div class="line">
            <div
              class="ball"
              :class="{ 'ball-right': competitiveness }"
              @click="competitiveness = !competitiveness"
            ></div>
          </div>
        </div>
        <div class="col-12 col-lg-4 my-3">
          <div
            class="bg-blue wow animate__animated animate__zoomIn"
            data-wow-duration="1s"
            v-show="competitiveness"
          >
            <div class="bg-text">
              短影音內容<br />
              精準獲取行為數據<br />
              建立AI物聯網
            </div>
          </div>
        </div>
        <div
          class="col-12 mt-3 wow animate__animated animate__zoomIn"
          data-wow-duration="1s"
          v-show="competitiveness"
        >
          <h3>翰成數位掌握短影音市場數據精萃</h3>
          <h3>未來將是台灣最重要 AI 數據管理公司</h3>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner";
import EightElements from "@/components/EightElements";

export default {
  components: {
    Banner,
    EightElements,
  },
  data() {
    return {
      competitiveness: false,
      banner: {
        url: require("../assets/hi8app/banner.png"),
        text: {
          title: "嗨吧 APP",
          content:
            "嗨吧 ! 向世界說 ~ 嗨、我們期望每個用戶透過嗨吧 APP 享受生活中的美好、感受嗨吧帶來的精彩影音、豐富我們生活每一天。",
        },
      },
      eight_elements: [
        {
          title: "社交",
          img: require("../assets/hi8app/icon_grid_14.svg"),
        },
        {
          title: "創造",
          img: require("../assets/hi8app/icon_grid_13.svg"),
        },
        {
          title: "娛樂",
          img: require("../assets/hi8app/icon_grid_12.svg"),
        },
        {
          title: "購物",
          img: require("../assets/hi8app/icon_grid_11.svg"),
        },
        {
          title: "",
          img: require("../assets/hi8app/icon_grid_han.svg"),
        },
        {
          title: "音樂",
          img: require("../assets/hi8app/icon_grid_10.svg"),
        },
        {
          title: "搜尋",
          img: require("../assets/hi8app/icon_grid_07.svg"),
        },
        {
          title: "商機",
          img: require("../assets/hi8app/icon_grid_08.svg"),
        },
        {
          title: "新聞",
          img: require("../assets/hi8app/icon_grid_09.svg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss">
.hi8app {
  .bg-cover {
    height: 28vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    .bg-text {
      color: white;
      font-weight: bold;
      font-size: 2.5rem;
      line-height: 28vw;
    }
    .bg-mask {
      background: rgba(0, 0, 0, 0.5);
      &:hover {
        background: rgba(0, 0, 0, 0.1);
        transition: 0.5s;
      }
    }
  }
  #competitiveness {
    h3 {
      font-weight: bold;
      color: #2575ed;
    }
    .bg-black {
      margin-right: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 23vw;
      height: 23vw;
      max-width: 300px;
      max-height: 300px;
      border-radius: 50%;
      -webkit-box-shadow: 5px 5px 25px #4444415d;
      -moz-box-shadow: 5px 5px 25px #4444415d;
      box-shadow: 5px 5px 25px #4444415d;
    }
    .bg-blue {
      margin-left: auto;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #2575ed;
      width: 23vw;
      height: 23vw;
      max-width: 300px;
      max-height: 300px;
      border-radius: 50%;
      -webkit-box-shadow: 5px 5px 25px #4444415d;
      -moz-box-shadow: 5px 5px 25px #4444415d;
      box-shadow: 5px 5px 25px #4444415d;
    }
    .bg-text {
      color: white;
      font-weight: bold;
      font-size: 1.5rem;
    }
    .center-line {
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        width: 70%;
        max-width: 300px;
        border-bottom: 3px solid black;
        position: relative;
      }
      .ball {
        cursor: pointer;
        width: 30px;
        height: 30px;
        background: #fff;
        border: 1px solid black;
        border-radius: 50%;
        position: absolute;
        left: 0;
        bottom: -15px;
      }
      .ball-right {
        animation-name: scroll;
        animation-duration: 1s;
        animation-timing-function: linear;
        animation-iteration-count: inherit;
        animation-direction: alternate;
        animation-fill-mode: forwards;
        @keyframes scroll {
          from {
            left: 0;
          }
          to {
            left: 100%;
          }
        }
      }
    }
  }
  @media screen and (max-width: 989px) {
    .bg-cover .bg-text {
      font-size: 1.5rem;
    }
    #competitiveness {
      h3 {
        font-size: 1.2rem;
      }
      .bg-black,
      .bg-blue {
        margin: 2rem auto;
        min-width: 200px;
        min-height: 200px;
      }
      .bg-text {
        font-size: 1rem;
      }
    }
  }
}
</style>
